import React from "react";
import { moldsData } from "../data.js";
import { IoMdCloseCircle } from "react-icons/io";

const Molds = ({ handleImageClick, handleDragStart, setMoldsVisible }) => {
  return (
    <div className="folder">
      <div className="top">
        <p>Все молды</p>
        <button onClick={() => setMoldsVisible(false)}><IoMdCloseCircle /></button>
      </div>
      {moldsData.map((image) => (
        // eslint-disable-next-line jsx-a11y/img-redundant-alt
        <img
          key={image.id}
          src={image.src}
          alt={`image-${image.id}`}
          draggable="true"
          onClick={() => handleImageClick(image.src)}
          onDragStart={handleDragStart}
        />
      ))}
    </div>
  );
};

export default Molds;
