import React from "react";

const Button = ({ selectedId, images, setImages, selectShape }) => {

  const handleDelete = () => {
    if (selectedId !== null) {
      setImages((prevImages) =>
        prevImages.filter((image) => image.id !== selectedId)
      );
      selectShape(null);
    }
  };

  const handleDuplicate = () => {
    if (selectedId !== null) {
      const selectedImage = images.find((image) => image.id === selectedId);
      if (selectedImage) {
        const duplicatedImage = {
          ...selectedImage,
          id: new Date().getTime(),
          x: selectedImage.x + 25,
          y: selectedImage.y + 25,
        };
        setImages((prevImages) => [...prevImages, duplicatedImage]);
        selectShape(duplicatedImage.id);
      }
    }
  };

  const handleMoveToTop = () => {
    if (selectedId !== null) {
      const selectedImage = images.find((image) => image.id === selectedId);
      if (selectedImage) {
        setImages((prevImages) => [
          ...prevImages.filter((image) => image.id !== selectedId),
          selectedImage,
        ]);
      }
    }
  };

  const handleMoveToBottom = () => {
    if (selectedId !== null) {
      const selectedImage = images.find((image) => image.id === selectedId);
      if (selectedImage) {
        setImages((prevImages) => [
          selectedImage,
          ...prevImages.filter((image) => image.id !== selectedId),
        ]);
      }
    }
  };

  return (
    <div className="button-block">
      <button>
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M15.6099 24C16.2092 24 16.641 23.5638 16.641 22.9793V19.6467H16.8966C20.3159 19.6467 22.4661 20.5016 24.0083 23.3544C24.3168 23.9128 24.7222 24 25.0923 24C25.5594 24 26 23.5812 26 22.831C26 16.3839 23.2417 12.3621 16.8966 12.3621H16.641V9.06434C16.641 8.47983 16.2092 8 15.5923 8C15.1605 8 14.8697 8.18321 14.4026 8.61941L7.44944 15.0578C7.10575 15.3806 7 15.7034 7 16C7 16.2879 7.11456 16.6194 7.44944 16.9335L14.4026 23.4329C14.8256 23.8255 15.1781 24 15.6099 24ZM15.1076 22.1243C15.0547 22.1243 15.0019 22.0981 14.9578 22.0458L8.6744 16.1658C8.6039 16.096 8.57746 16.0523 8.57746 16C8.57746 15.9477 8.6039 15.8953 8.6744 15.8342L14.949 9.87568C14.993 9.84079 15.0459 9.80589 15.0988 9.80589C15.1781 9.80589 15.2222 9.85823 15.2222 9.92803V13.3915C15.2222 13.5921 15.3191 13.6794 15.5218 13.6794H16.7027C22.7481 13.6794 24.5724 17.8321 24.7486 21.9498C24.7486 22.0196 24.7222 22.0458 24.6781 22.0458C24.6429 22.0458 24.6252 22.0196 24.5988 21.9586C23.5589 19.7688 20.8887 18.3293 16.7027 18.3293H15.5218C15.3191 18.3293 15.2222 18.4166 15.2222 18.6172V21.9935C15.2222 22.072 15.1781 22.1243 15.1076 22.1243Z" fill="#B3B3B3"/>
        </svg>
      </button>
      <button>
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M17.3901 24C17.8219 24 18.1744 23.8255 18.5974 23.4329L25.5506 16.9335C25.8854 16.6194 26 16.2879 26 16C26 15.7034 25.8942 15.3806 25.5506 15.0578L18.5974 8.61941C18.1303 8.18321 17.8395 8 17.4077 8C16.7908 8 16.3502 8.47983 16.3502 9.06434V12.3621H16.0946C9.75835 12.3621 7 16.3839 7 22.831C7 23.5812 7.43182 24 7.9077 24C8.27783 24 8.68321 23.9128 8.99165 23.3544C10.525 20.5016 12.6841 19.6467 16.0946 19.6467H16.3502V22.9793C16.3502 23.5638 16.7908 24 17.3901 24ZM17.8924 22.1243C17.8219 22.1243 17.769 22.072 17.769 21.9935V18.6172C17.769 18.4166 17.6809 18.3293 17.4782 18.3293H16.2973C12.1113 18.3293 9.44109 19.7688 8.40121 21.9586C8.37477 22.0196 8.35714 22.0458 8.31308 22.0458C8.27783 22.0458 8.25139 22.0196 8.25139 21.9498C8.42764 17.8321 10.243 13.6794 16.2973 13.6794H17.4782C17.6809 13.6794 17.769 13.5921 17.769 13.3915V9.92803C17.769 9.85823 17.8219 9.80589 17.9012 9.80589C17.9541 9.80589 18.007 9.84079 18.051 9.87568L24.3256 15.8342C24.3873 15.8953 24.4137 15.9477 24.4137 16C24.4137 16.0523 24.3961 16.096 24.3256 16.1658L18.0422 22.0458C17.9981 22.0981 17.9453 22.1243 17.8924 22.1243Z" fill="#B3B3B3"/>
        </svg>
      </button>
      <button onClick={handleDuplicate} disabled={selectedId === null}>
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M9.82422 20.8711H11.2437V22.1411C11.2437 23.8511 12.1069 24.7144 13.8501 24.7144H22.1841C23.9106 24.7144 24.7822 23.8511 24.7822 22.1411V13.7407C24.7822 12.0308 23.9106 11.1675 22.1841 11.1675H20.7563V9.89746C20.7563 8.1875 19.8848 7.32422 18.1582 7.32422H9.82422C8.08105 7.32422 7.21777 8.1875 7.21777 9.89746V18.2979C7.21777 20.0078 8.08105 20.8711 9.82422 20.8711ZM9.84082 19.5347C9.01074 19.5347 8.5542 19.0864 8.5542 18.2231V9.97217C8.5542 9.10889 9.01074 8.66064 9.84082 8.66064H18.1333C18.9551 8.66064 19.4199 9.10889 19.4199 9.97217V11.1675H13.8501C12.1069 11.1675 11.2437 12.0225 11.2437 13.7407V19.5347H9.84082ZM13.8667 23.3779C13.0449 23.3779 12.5801 22.9297 12.5801 22.0664V13.8154C12.5801 12.9521 13.0449 12.5039 13.8667 12.5039H22.1592C22.981 12.5039 23.4458 12.9521 23.4458 13.8154V22.0664C23.4458 22.9297 22.981 23.3779 22.1592 23.3779H13.8667Z" fill="#B3B3B3"/>
          <path d="M18.1157 21.5952C18.564 21.5952 18.8213 21.2964 18.8213 20.8149V18.5737H21.2119C21.6768 18.5737 22.0005 18.3247 22.0005 17.8848C22.0005 17.4365 21.6934 17.1792 21.2119 17.1792H18.8213V14.7886C18.8213 14.2988 18.564 14 18.1157 14C17.6758 14 17.4351 14.3154 17.4351 14.7886V17.1792H15.0527C14.5713 17.1792 14.2559 17.4365 14.2559 17.8848C14.2559 18.3247 14.5879 18.5737 15.0527 18.5737H17.4351V20.8149C17.4351 21.2798 17.6758 21.5952 18.1157 21.5952Z" fill="#B3B3B3"/>
        </svg>
      </button>
      <button>
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M20.7059 9.22032C22.7024 10.6321 24 12.919 24 15.5C24 19.7882 20.4183 23.2644 16 23.2644H15.5294M11.2941 21.7797C9.29757 20.3679 8 18.081 8 15.5C8 11.2118 11.5817 7.73558 16 7.73558H16.4706M16.9412 25L15.0588 23.1731L16.9412 21.3462M15.0588 9.65385L16.9412 7.82692L15.0588 6" stroke="#B3B3B3" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </button>
      <button onClick={handleDelete} disabled={selectedId === null}>
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12.6904 24.5151H20.3022C21.4893 24.5151 22.2861 23.7432 22.3442 22.5562L22.917 10.4868H23.9131C24.2534 10.4868 24.5356 10.1963 24.5356 9.85596C24.5356 9.51562 24.2534 9.2334 23.9131 9.2334H20.1196V7.96338C20.1196 6.75977 19.3394 6.0293 18.061 6.0293H14.915C13.6367 6.0293 12.8564 6.75977 12.8564 7.96338V9.2334H9.07959C8.74756 9.2334 8.45703 9.51562 8.45703 9.85596C8.45703 10.2046 8.74756 10.4868 9.07959 10.4868H10.0757L10.6484 22.5645C10.7065 23.7515 11.4951 24.5151 12.6904 24.5151ZM14.1763 8.04639C14.1763 7.57324 14.5 7.26611 14.998 7.26611H17.978C18.4761 7.26611 18.7998 7.57324 18.7998 8.04639V9.2334H14.1763V8.04639ZM12.8066 23.2617C12.3335 23.2617 11.9766 22.9048 11.9517 22.415L11.3706 10.4868H21.5806L21.041 22.415C21.0161 22.9131 20.6592 23.2617 20.1694 23.2617H12.8066ZM14.0352 21.8423C14.3506 21.8423 14.5581 21.6431 14.5498 21.3525L14.3008 12.4707C14.2925 12.1802 14.0767 11.9893 13.7778 11.9893C13.4624 11.9893 13.2549 12.1885 13.2632 12.479L13.5205 21.3525C13.5288 21.6514 13.728 21.8423 14.0352 21.8423ZM16.5005 21.8423C16.8159 21.8423 17.0317 21.6431 17.0317 21.3525V12.479C17.0317 12.1885 16.8159 11.9893 16.5005 11.9893C16.1851 11.9893 15.9609 12.1885 15.9609 12.479V21.3525C15.9609 21.6431 16.1851 21.8423 16.5005 21.8423ZM18.9575 21.8423C19.2563 21.8423 19.4639 21.6514 19.4722 21.3525L19.7295 12.479C19.7378 12.1885 19.522 11.9893 19.2065 11.9893C18.916 11.9893 18.7002 12.1802 18.6919 12.479L18.4429 21.3525C18.4346 21.6431 18.6421 21.8423 18.9575 21.8423Z" fill="#B3B3B3"/>
        </svg>
      </button>
      <button onClick={handleMoveToTop} disabled={selectedId === null}>
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M23 13.5H15C14.1716 13.5 13.5 14.1716 13.5 15V23C13.5 23.8284 14.1716 24.5 15 24.5H23C23.8284 24.5 24.5 23.8284 24.5 23V15C24.5 14.1716 23.8284 13.5 23 13.5ZM15 12C13.3431 12 12 13.3431 12 15V23C12 24.6569 13.3431 26 15 26H23C24.6569 26 26 24.6569 26 23V15C26 13.3431 24.6569 12 23 12H15Z" fill="black"/>
          <path d="M9.75 12C9.75 10.7574 10.7574 9.75 12 9.75H20C21.2426 9.75 22.25 10.7574 22.25 12V20C22.25 21.2426 21.2426 22.25 20 22.25H12C10.7574 22.25 9.75 21.2426 9.75 20V12Z" fill="white" stroke="black" stroke-width="1.5"/>
          <path d="M6 8C6 6.89543 6.89543 6 8 6H18C19.1046 6 20 6.89543 20 8V18C20 19.1046 19.1046 20 18 20H8C6.89543 20 6 19.1046 6 18V8Z" fill="black"/>
        </svg>
      </button>
      <button onClick={handleMoveToBottom} disabled={selectedId === null}>
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12.75 15C12.75 13.7574 13.7574 12.75 15 12.75H23C24.2426 12.75 25.25 13.7574 25.25 15V23C25.25 24.2426 24.2426 25.25 23 25.25H15C13.7574 25.25 12.75 24.2426 12.75 23V15Z" fill="black" stroke="black" stroke-width="1.5"/>
          <path d="M9.75 12C9.75 10.7574 10.7574 9.75 12 9.75H20C21.2426 9.75 22.25 10.7574 22.25 12V20C22.25 21.2426 21.2426 22.25 20 22.25H12C10.7574 22.25 9.75 21.2426 9.75 20V12Z" fill="white" stroke="black" stroke-width="1.5"/>
          <path d="M6.75 8C6.75 7.30964 7.30964 6.75 8 6.75H18C18.6904 6.75 19.25 7.30964 19.25 8V18C19.25 18.6904 18.6904 19.25 18 19.25H8C7.30964 19.25 6.75 18.6904 6.75 18V8Z" fill="white" stroke="black" stroke-width="1.5"/>
        </svg>
      </button>
    </div>
  );
};

export default Button;
