import React, { useState } from "react";
import { fontsData } from "../data.js";
import { IoMdCloseCircle } from "react-icons/io";
import { FaStar } from "react-icons/fa";

const Fonts = ({ handleTextClick, handleDragStart, setFontsVisible }) => {
  // Состояние для хранения активных звезд
  const [activeStars, setActiveStars] = useState({});

  const handleStarClick = (id) => {
    // Обновление состояния, чтобы изменить цвет звезды при клике
    setActiveStars((prev) => ({
      ...prev,
      [id]: !prev[id],  // Переключение состояния звезды
    }));
  };

  return (
    <div className="folder">
      <div className="top">
        <p>Текст</p>
        <button onClick={() => setFontsVisible(false)}>
          <IoMdCloseCircle />
        </button>
      </div>
      {fontsData.map((font) => (
        <div
          key={font.id}
          style={{
            fontFamily: `${font.family}`,
            fontWeight: `${font.weight}`,
            border: "1px solid #D0D5DD",  // тонкая рамка
            padding: "10px",            // отступы внутри рамки
            marginBottom: "10px",       // отступ между элементами
            borderRadius: "15px",       // закругленные углы
            width: "90%",           // фиксированная ширина рамки
            height: "65px",             // увеличенная высота рамки для размещения крупного текста
            position: "relative",       // относительное позиционирование для контейнера
            cursor: "pointer",          // указатель при наведении
            display: "flex",            // использование Flexbox
            alignItems: "flex-start",   // основной текст вверху рамки
            justifyContent: "flex-start" // основной текст слева в рамке
          }}
          alt={`font-${font.id}`}
          draggable="true"
          onClick={() => handleTextClick(font.preview)}
          onDragStart={handleDragStart}
        >
          <span
            style={{
              fontSize: "35px",           // увеличенный размер шрифта для основного текста
              fontWeight: "bold"          // увеличенная толщина текста для выразительности
            }}
          >
            {font.preview}
          </span>
          <span
            style={{
              fontSize: "12px",            // уменьшенный размер шрифта для нижнего текста
              position: "absolute",        // абсолютное позиционирование
              bottom: "5px",               // отступ от нижнего края рамки
              left: "10px"                 // отступ от левого края рамки
            }}
          >
            {font.subText}  {/* индивидуальный дополнительный текст */}
          </span>
          {/* Звездочка в правом верхнем углу */}
          <FaStar
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
              color: activeStars[font.id] ? "gold" : "white", // Цвет заливки зависит от состояния
              stroke: "black",   // Цвет обводки
              strokeWidth: "20",  // Толщина обводки
              fontSize: "20px",  // Размер звезды
              cursor: "pointer"  // Указатель при наведении на звезду
            }}
            onClick={() => handleStarClick(font.id)} // Обработчик клика на звезду
          />
        </div>
      ))}
    </div>
  );
};

export default Fonts;
