import React from "react";

const ConstructorMenu = ({ setImagesVisible, setMoldsVisible, setGeometryVisiable, setFontsVisible, setFavoritesVisible}) => {

    function change(number){
        switch(number){
            case 1: 
                setMoldsVisible(true);
                setImagesVisible(false);
                setGeometryVisiable(false);
                setFontsVisible(false);
                setFavoritesVisible(false);
                break;
            case 2: 
                setMoldsVisible(false);
                setImagesVisible(true);
                setGeometryVisiable(false);
                setFontsVisible(false);
                setFavoritesVisible(false);
                break;
            case 3:
                setMoldsVisible(false);
                setImagesVisible(false);
                setGeometryVisiable(true);
                setFontsVisible(false);
                setFavoritesVisible(false);
                break;
            case 4: 
                setMoldsVisible(false);
                setImagesVisible(false);
                setGeometryVisiable(false);
                setFontsVisible(true);
                setFavoritesVisible(false);
                break;
            case 5: 
                setMoldsVisible(false);
                setImagesVisible(false);
                setGeometryVisiable(false);
                setFontsVisible(false);
                setFavoritesVisible(true);
                break;
            default:
                break;
        }
    }

    return (
        <div id="constructor-menu">
            <button onClick={() => change(1)}>
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.86746 15.1934H13.3259C14.5676 15.1934 15.1934 14.5676 15.1934 13.277V8.90657C15.1934 7.61597 14.5676 7 13.3259 7H8.86746C7.62575 7 7 7.61597 7 8.90657V13.277C7 14.5676 7.62575 15.1934 8.86746 15.1934ZM18.6839 15.1934H23.1325C24.3743 15.1934 25 14.5676 25 13.277V8.90657C25 7.61597 24.3743 7 23.1325 7H18.6839C17.4324 7 16.8066 7.61597 16.8066 8.90657V13.277C16.8066 14.5676 17.4324 15.1934 18.6839 15.1934ZM8.88702 13.8148C8.54481 13.8148 8.3786 13.6388 8.3786 13.277V8.90657C8.3786 8.55459 8.54481 8.3786 8.88702 8.3786H13.2966C13.6388 8.3786 13.8148 8.55459 13.8148 8.90657V13.277C13.8148 13.6388 13.6388 13.8148 13.2966 13.8148H8.88702ZM18.7034 13.8148C18.3514 13.8148 18.1852 13.6388 18.1852 13.277V8.90657C18.1852 8.55459 18.3514 8.3786 18.7034 8.3786H23.113C23.4552 8.3786 23.6214 8.55459 23.6214 8.90657V13.277C23.6214 13.6388 23.4552 13.8148 23.113 13.8148H18.7034ZM8.86746 25H13.3259C14.5676 25 15.1934 24.384 15.1934 23.0934V18.7132C15.1934 17.4324 14.5676 16.8066 13.3259 16.8066H8.86746C7.62575 16.8066 7 17.4324 7 18.7132V23.0934C7 24.384 7.62575 25 8.86746 25ZM18.6839 25H23.1325C24.3743 25 25 24.384 25 23.0934V18.7132C25 17.4324 24.3743 16.8066 23.1325 16.8066H18.6839C17.4324 16.8066 16.8066 17.4324 16.8066 18.7132V23.0934C16.8066 24.384 17.4324 25 18.6839 25ZM8.88702 23.6214C8.54481 23.6214 8.3786 23.4454 8.3786 23.0934V18.723C8.3786 18.3612 8.54481 18.1852 8.88702 18.1852H13.2966C13.6388 18.1852 13.8148 18.3612 13.8148 18.723V23.0934C13.8148 23.4454 13.6388 23.6214 13.2966 23.6214H8.88702ZM18.7034 23.6214C18.3514 23.6214 18.1852 23.4454 18.1852 23.0934V18.723C18.1852 18.3612 18.3514 18.1852 18.7034 18.1852H23.113C23.4552 18.1852 23.6214 18.3612 23.6214 18.723V23.0934C23.6214 23.4454 23.4552 23.6214 23.113 23.6214H18.7034Z" fill="black"/>
                </svg>
            </button>
            <button onClick={() => change(2)}>
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.66615 21.022H9.28792V22.3365C9.28792 24.1064 10.171 25 11.9541 25H24.3423C26.1085 25 27 24.1064 27 22.3365V13.6415C27 11.8716 26.1085 10.978 24.3423 10.978H22.7121V9.66348C22.7121 7.89356 21.8205 7 20.0544 7H7.66615C5.88306 7 5 7.89356 5 9.66348V18.3585C5 20.1284 5.88306 21.022 7.66615 21.022ZM7.68313 19.6387C6.83404 19.6387 6.36704 19.1747 6.36704 18.2811V9.74081C6.36704 8.84726 6.83404 8.38329 7.68313 8.38329H20.0289C20.8695 8.38329 21.345 8.84726 21.345 9.74081V10.978H11.9541C10.171 10.978 9.28792 11.863 9.28792 13.6415V19.6387H7.68313ZM10.655 13.7189C10.655 12.8253 11.122 12.3613 11.9711 12.3613H24.3169C25.1575 12.3613 25.633 12.8253 25.633 13.7189V20.558L22.5592 17.611C22.1941 17.2673 21.7441 17.0955 21.2941 17.0955C20.8356 17.0955 20.4195 17.2587 20.0374 17.6024L16.2675 20.9876L14.7561 19.6129C14.408 19.295 14.0259 19.1231 13.6268 19.1231C13.2532 19.1231 12.9051 19.2864 12.5484 19.6043L10.655 21.2625V13.7189ZM14.9004 17.8258C15.9788 17.8258 16.8703 16.9236 16.8703 15.8153C16.8703 14.7241 15.9788 13.8134 14.9004 13.8134C13.8051 13.8134 12.9135 14.7241 12.9135 15.8153C12.9135 16.9236 13.8051 17.8258 14.9004 17.8258Z" fill="black"/>
                </svg>
            </button>
            <button onClick={() => change(3)}>
                <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12.4637 14.8483H3.22464C2.07871 14.8483 1.03509 14.2548 0.451891 13.2622C-0.131308 12.2696 -0.151771 11.0825 0.400733 10.0694L5.01516 1.66804C5.58813 0.624261 6.64198 3.05176e-05 7.83907 3.05176e-05C9.03615 3.05176e-05 10.09 0.624261 10.663 1.66804L15.2876 10.0694C15.8401 11.0722 15.8197 12.2696 15.2365 13.2622C14.6533 14.2548 13.6097 14.8483 12.4637 14.8483ZM7.83907 1.54524C7.21495 1.54524 6.66244 1.87271 6.36573 2.41507L1.7513 10.8164C1.46481 11.3485 1.47505 11.9625 1.77176 12.4844C2.07871 13.0063 2.62098 13.3133 3.22464 13.3133H12.4637C13.0674 13.3133 13.6097 13.0063 13.9166 12.4844C14.2235 11.9625 14.2338 11.3485 13.9371 10.8164L9.3124 2.41507C9.01569 1.87271 8.46319 1.54524 7.83907 1.54524Z" fill="black"/>
                    <path d="M14.5819 22.0113C10.4893 22.0113 7.16406 18.6856 7.16406 14.5923C7.16406 14.4081 7.17429 14.2239 7.18453 14.0397C7.20499 13.6304 7.54263 13.3132 7.95189 13.3132H12.464C13.0677 13.3132 13.6099 13.0062 13.9169 12.4843C14.2238 11.9624 14.2341 11.3484 13.9374 10.8163L12.6277 8.43199C12.5049 8.2171 12.5049 7.96125 12.6072 7.73612C12.7096 7.51099 12.9142 7.35751 13.1495 7.30634C13.6099 7.21424 14.0908 7.17331 14.5819 7.17331C18.6746 7.17331 21.9998 10.4991 21.9998 14.5923C21.9998 18.6856 18.6746 22.0113 14.5819 22.0113ZM8.70903 14.8481C8.84204 17.9795 11.4306 20.4764 14.5819 20.4764C17.8253 20.4764 20.4651 17.8362 20.4651 14.5923C20.4651 11.3484 17.8253 8.70828 14.5819 8.70828C14.5615 8.70828 14.5512 8.70828 14.5308 8.70828L15.2879 10.0795C15.8404 11.0824 15.82 12.2796 15.2368 13.2722C14.6536 14.2649 13.6202 14.8584 12.464 14.8584H8.70903V14.8481Z" fill="black"/>
                </svg>
            </button>
            <button onClick={() => change(4)}>
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4.86561 23.8816C5.41176 23.8816 5.67969 23.6664 5.87548 23.0746L7.20481 19.2656H13.2847L14.614 23.0746C14.8098 23.6664 15.0777 23.8816 15.6136 23.8816C16.1597 23.8816 16.5101 23.5373 16.5101 22.9993C16.5101 22.8164 16.4792 22.655 16.3967 22.4183L11.5638 8.98991C11.3267 8.3228 10.9043 8 10.2447 8C9.60584 8 9.17304 8.3228 8.94633 8.97915L4.11335 22.4291C4.03091 22.6658 4 22.8272 4 23.0101C4 23.5481 4.32976 23.8816 4.86561 23.8816ZM7.70975 17.6409L10.2138 10.3995H10.2653L12.7694 17.6409H7.70975ZM22.5487 23.9892C24.3109 23.9892 25.6505 23.1177 26.2791 21.5683H26.3409V23.0962C26.3409 23.6557 26.681 24 27.1653 24C27.6599 24 28 23.6449 28 23.0854V12.885C28 12.3362 27.6599 11.9489 27.1653 11.9489C26.681 11.9489 26.3409 12.3362 26.3409 12.885V14.4022H26.2791C25.6505 12.885 24.249 11.9704 22.5487 11.9704C19.7561 11.9704 17.8909 14.3914 17.8909 17.9852C17.8909 21.6005 19.7561 23.9892 22.5487 23.9892ZM22.9609 22.4183C20.9515 22.4183 19.6737 20.7075 19.6737 17.9852C19.6737 15.2629 20.9515 13.5414 22.9609 13.5414C24.9704 13.5414 26.2894 15.3167 26.2894 18.0067C26.2894 20.6967 24.991 22.4183 22.9609 22.4183Z" fill="black"/>
                </svg>
            </button>
            <div>
                <svg width="32" height="1" viewBox="0 0 32 1" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <line y1="0.5" x2="32" y2="0.5" stroke="#D9D9D9"/>
                </svg>
            </div>
            <button onClick={() => change(5)}>
                <svg width="24" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4.60477 22.8011C5.04785 23.1479 5.60981 23.0287 6.27983 22.541L11.9967 18.3254L17.7243 22.541C18.3943 23.0287 18.9455 23.1479 19.3994 22.8011C19.8425 22.4652 19.9397 21.9125 19.6695 21.1214L17.4109 14.3807L23.1818 10.2193C23.8518 9.74249 24.122 9.24398 23.9491 8.70213C23.7762 8.18195 23.2682 7.92187 22.4361 7.9327L15.3576 7.97605L13.207 1.20291C12.9477 0.40097 12.5586 0 11.9967 0C11.4455 0 11.0565 0.40097 10.7971 1.20291L8.64654 7.97605L1.56804 7.9327C0.735906 7.92187 0.227983 8.18195 0.0550731 8.70213C-0.128644 9.24398 0.152335 9.74249 0.822361 10.2193L6.59323 14.3807L4.33459 21.1214C4.06442 21.9125 4.16168 22.4652 4.60477 22.8011ZM6.16096 20.6554C6.13934 20.6337 6.15015 20.6229 6.16096 20.5578L8.31152 14.3591C8.46282 13.9364 8.37636 13.6005 7.99812 13.3404L2.6163 9.61244C2.56227 9.57993 2.55146 9.55826 2.56227 9.52575C2.57307 9.49323 2.59469 9.49323 2.65953 9.49323L9.19769 9.61244C9.64077 9.62328 9.92175 9.43905 10.0622 8.99473L11.9426 2.72009C11.9534 2.65507 11.9751 2.6334 11.9967 2.6334C12.0291 2.6334 12.0507 2.65507 12.0615 2.72009L13.9419 8.99473C14.0824 9.43905 14.3634 9.62328 14.8065 9.61244L21.3446 9.49323C21.4095 9.49323 21.4311 9.49323 21.4419 9.52575C21.4527 9.55826 21.4311 9.57993 21.3878 9.61244L16.006 13.3404C15.6278 13.6005 15.5413 13.9364 15.6926 14.3591L17.8432 20.5578C17.854 20.6229 17.8648 20.6337 17.8432 20.6554C17.8216 20.6879 17.7892 20.6662 17.7459 20.6337L12.5478 16.6565C12.202 16.3856 11.8021 16.3856 11.4563 16.6565L6.25822 20.6337C6.21499 20.6662 6.18257 20.6879 6.16096 20.6554Z" fill="black"/>
                </svg>
            </button>
            <button onClick={() => change(6)}>
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M17.5482 10.3226L16.3967 8.01952C16.0653 7.35669 15.8995 7.02526 15.6523 6.78313C15.4337 6.56901 15.1702 6.40616 14.8809 6.30638C14.5538 6.19354 14.1832 6.19354 13.4422 6.19354H9.49659C8.34035 6.19354 7.76223 6.19354 7.3206 6.41856C6.93214 6.61649 6.61631 6.93232 6.41838 7.32079C6.19336 7.76241 6.19336 8.34053 6.19336 9.49677V10.3226M6.19336 10.3226H21.8837C23.618 10.3226 24.4852 10.3226 25.1477 10.6601C25.7303 10.957 26.2041 11.4307 26.501 12.0134C26.8385 12.6759 26.8385 13.5431 26.8385 15.2774V19.8193C26.8385 21.5537 26.8385 22.4209 26.501 23.0833C26.2041 23.666 25.7303 24.1398 25.1477 24.4367C24.4852 24.7742 23.618 24.7742 21.8837 24.7742H11.1482C9.41384 24.7742 8.54666 24.7742 7.88423 24.4367C7.30153 24.1398 6.82779 23.666 6.53089 23.0833C6.19336 22.4209 6.19336 21.5537 6.19336 19.8193V10.3226ZM13.4192 17.5484L16.5159 20.6452M16.5159 20.6452L19.6127 17.5484M16.5159 20.6452V14.4516" stroke="black" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </button>
        </div>
    );
}

export default ConstructorMenu;
