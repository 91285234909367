import React, { useState } from "react";
import { geomData, stickerData } from "../data.js";
import { IoMdCloseCircle } from "react-icons/io";
// import IoIosArrowBack from "../img/Chevron-left.svg"

const Geometry = ({ handleImageClick, handleDragStart, setGeometryVisiable }) => {
  const [currentScreen, setCurrentScreen] = useState("objects");
  const [selectedStickerPack, setSelectedStickerPack] = useState(null); // Состояние для выбранного стикерпака

  const goToStickers = () => {
    setCurrentScreen("stickers");
  };

  const goToObjects = () => {
    setCurrentScreen("objects");
    setSelectedStickerPack(null); // Сбрасываем выбранный стикерпак при возврате
  };

  const handleStickerPackClick = (stickerPack) => {
    setSelectedStickerPack(stickerPack);
    setCurrentScreen("stickerDetails"); // Переходим на экран с деталями стикерпака
  };

  const goBackToStickerPacks = () => {
    setSelectedStickerPack(null);
    setCurrentScreen("stickers"); // Возврат на экран стикерпака
  };

  const handleClose = () => {
    setGeometryVisiable(false);
  };

  return (
    <div className="folder">
      {currentScreen === "objects" && (
        <>
          <div className="top">
            <p>Объекты</p>
            <button onClick={handleClose}>
              <IoMdCloseCircle />
            </button>
          </div>

          <div className="section">
            <p className="section-title">Фигуры</p>
            <div className="image-container horizontal-scroll">
              {geomData.map((geom) => (
                <img
                  key={geom.id}
                  src={geom.src}
                  alt={`figure-${geom.id}`}
                  draggable="true"
                  className="image"
                  onClick={() => handleImageClick(geom.src)}
                  onDragStart={handleDragStart}
                />
              ))}
            </div>
          </div>

          <div className="section">
            <p 
              className="section-title"
              onClick={goToStickers}
              style={{ cursor: "pointer" }}
            >
              Стикерпаки
            </p>
            <div className="image-container horizontal-scroll">
              {stickerData.map((stickerPack) => (
                <img
                  key={stickerPack.id}
                  src={stickerPack.src}
                  alt={`sticker-${stickerPack.id}`}
                  draggable="true"
                  className="image"
                  onClick={() => handleStickerPackClick(stickerPack)} // Переход на экран стикерпака
                  onDragStart={handleDragStart}
                />
              ))}
            </div>
          </div>
        </>
      )}

      {currentScreen === "stickers" && !selectedStickerPack && (
        <>
          <div className="top">
            <button onClick={goToObjects} style={{ display: "flex", alignItems: "center", color: "#747474" }}>
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11 16.2051C11 16.4126 11.0747 16.5952 11.2324 16.7529L17.8149 23.186C17.9561 23.3354 18.1387 23.4102 18.3545 23.4102C18.7861 23.4102 19.1182 23.0864 19.1182 22.6548C19.1182 22.439 19.0269 22.2563 18.894 22.1152L12.8511 16.2051L18.894 10.2949C19.0269 10.1538 19.1182 9.96289 19.1182 9.75537C19.1182 9.32373 18.7861 9 18.3545 9C18.1387 9 17.9561 9.07471 17.8149 9.21582L11.2324 15.6572C11.0747 15.8066 11 15.9976 11 16.2051Z" fill="#747474"/>
            </svg>
            </button>
            <p>Стикерпаки</p>
            <button onClick={handleClose}>
              <IoMdCloseCircle />
            </button>
          </div>
          <div className="section">
            <div className="image-container vertical-scroll">
              {stickerData.map((stickerPack) => (
                <img
                  key={stickerPack.id}
                  src={stickerPack.src}
                  alt={`sticker-${stickerPack.id}`}
                  draggable="true"
                  className="image"
                  onClick={() => handleStickerPackClick(stickerPack)} // Переход на экран стикерпака
                  onDragStart={handleDragStart}
                />
              ))}
            </div>
          </div>
        </>
      )}

      {currentScreen === "stickerDetails" && selectedStickerPack && (
        <>
          <div className="top">
            <button onClick={goBackToStickerPacks} style={{ display: "flex", alignItems: "center", color: "#747474" }}>
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11 16.2051C11 16.4126 11.0747 16.5952 11.2324 16.7529L17.8149 23.186C17.9561 23.3354 18.1387 23.4102 18.3545 23.4102C18.7861 23.4102 19.1182 23.0864 19.1182 22.6548C19.1182 22.439 19.0269 22.2563 18.894 22.1152L12.8511 16.2051L18.894 10.2949C19.0269 10.1538 19.1182 9.96289 19.1182 9.75537C19.1182 9.32373 18.7861 9 18.3545 9C18.1387 9 17.9561 9.07471 17.8149 9.21582L11.2324 15.6572C11.0747 15.8066 11 15.9976 11 16.2051Z" fill="#747474"/>
            </svg>
            </button>
            <p>{selectedStickerPack.name}</p> {/* Отображаем название стикерпака */}
            <button onClick={handleClose}>
              <IoMdCloseCircle />
            </button>
          </div>
          <div className="section">
            <div className="image-container vertical-scroll">
              {selectedStickerPack.stickers.map((sticker, index) => (
                <img
                  key={index}
                  src={sticker.src}
                  alt={`sticker-${index}`}
                  draggable="true"
                  className="image"
                  onClick={() => handleImageClick(sticker.src)}
                  onDragStart={handleDragStart}
                />
              ))}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Geometry;
