import React from "react";
import { IoMdCloseCircle} from "react-icons/io";

const Favourite = ({ setFavoritesVisible }) => {
  return (
    <div className="folder">
      <div className="top">
        <p>Избранное</p>
        <button onClick={() => setFavoritesVisible(false)}>
          <IoMdCloseCircle />
        </button>
      </div>
      <div>
      <svg width="68" height="68" viewBox="0 0 68 68" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="5" y="5" width="58" height="58" rx="29" fill="#FEF0C7"/>
        <rect x="5" y="5" width="58" height="58" rx="29" stroke="#FFFAEB" stroke-width="9.66667"/>
      <path d="M28.3143 42.0752C28.6546 42.3408 29.0862 42.2495 29.6009 41.876L33.992 38.647L38.3914 41.876C38.9061 42.2495 39.3294 42.3408 39.678 42.0752C40.0184 41.8179 40.0931 41.3945 39.8856 40.7886L38.1507 35.6255L42.5833 32.438C43.098 32.0728 43.3055 31.6909 43.1727 31.2759C43.0399 30.8774 42.6497 30.6782 42.0106 30.6865L36.5736 30.7197L34.9217 25.5317C34.7225 24.9175 34.4236 24.6104 33.992 24.6104C33.5687 24.6104 33.2698 24.9175 33.0706 25.5317L31.4188 30.7197L25.9818 30.6865C25.3426 30.6782 24.9525 30.8774 24.8196 31.2759C24.6785 31.6909 24.8944 32.0728 25.409 32.438L29.8416 35.6255L28.1068 40.7886C27.8992 41.3945 27.9739 41.8179 28.3143 42.0752Z" fill="#FFCE4F"/>
      </svg>
      </div>
      <div className="content-box">
        <p>Здесь будут отображаться фото и элементы, которым вы поставите “Нравится”</p>
      </div>
    </div>
  );
};

export default Favourite;
