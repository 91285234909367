import React, { useState } from "react";
import { imagesData as initialImagesData } from "../data.js";
import { IoMdCloseCircle } from "react-icons/io";
import { FiDownload } from "react-icons/fi";

const Image = ({ handleImageClick, handleDragStart, setImagesVisible }) => {
  const [imagesData, setImagesData] = useState(initialImagesData);
  const [nextId, setNextId] = useState(initialImagesData.length + 1);

  const handleImageUpload = (event) => {
    const files = Array.from(event.target.files);
    const newImages = files.map((file) => {
      return {
        id: nextId,
        src: URL.createObjectURL(file),
      };
    });

    setImagesData((prevImages) => [...prevImages, ...newImages]);
    setNextId((prevId) => prevId + newImages.length);
  };

  return (
    <div className="folder">
      <div className="top">
        <p>Изображения</p>
        <button onClick={() => setImagesVisible(false)}>
          <IoMdCloseCircle />
        </button>
      </div>
      
      <div className="upload-container">
        <label className="upload-button">
          <FiDownload className="upload-icon" />
          Загрузить из файлов
          <input
            type="file"
            accept="image/*"
            multiple
            onChange={handleImageUpload}
            style={{ display: "none" }}
          />
        </label>
      </div>

      <div className="content-box">
        {imagesData.map((image) => (
          <img
            key={image.id}
            src={image.src}
            alt={`ID ${image.id}`}
            draggable="true"
            onClick={() => handleImageClick(image.src)}
            onDragStart={handleDragStart}
          />
        ))}
      </div>
    </div>
  );
};

export default Image;
