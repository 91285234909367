import React, { useRef, useState } from "react";
import { Stage, Layer } from "react-konva";
import URLImage from "./components/URLImage";
import Button from "./components/Button";
import Image from "./components/Image";
import NavBar from "./components/Navbar";
import ConstructorMenu from "./components/ConstructorMenu";
import Molds from "./components/Molds";
import Geometry from "./components/Geometry";
import Fonts from "./components/Fonts";
import "./css/fonts.css";
import URLText from "./components/URLText";
import Favourite from "./components/Favourite";

const App = () => {
  const dragUrl = useRef(); 
  const stageRef = useRef(); 
  const [images, setImages] = React.useState([]); 
  const [selectedId, selectShape] = React.useState(null);
  const [imagesVisible, setImagesVisible] = useState(false);
  const [moldsVisible, setMoldsVisible] = useState(false);
  const [geometryVisible, setGeometryVisiable] = useState(false);
  const [fontsVisible, setFontsVisible] = useState(false);
  const [favoritesVisible, setFavoritesVisible] = useState(false);

  //text
  const [text, setText] = useState("Click to resize. Double click to edit.");
  const [texts, setTexts] = React.useState([]);
  const [width, setWidth] = useState(200);
  const [height, setHeight] = useState(200);
  const [selected, setSelected] = useState(false);

  const handleDragStart = (e) => {
    dragUrl.current = e.target.src;
  };
 
  const handleDrop = (e) => {
    e.preventDefault(); 
    stageRef.current.setPointersPositions(e); 
    const id = new Date().getTime(); 
    setImages((prevImages) => [
      ...prevImages,
      {
        id,
        ...stageRef.current.getPointerPosition(),
        src: dragUrl.current,
      },
    ]);
    selectShape(id);
  };

  const handleImageDragMove = (e, id) => {
    const newImages = images.map((image) =>
      image.id === id
        ? {
            ...image,
            x: e.target.x(),
            y: e.target.y(),
          }
        : image
    );
    setImages(newImages);
  };

  const handleTextDragMove = (e, id) => {
    const newText = text.map((text) =>
      text.id === id
        ? {
            ...text,
            x: e.target.x(),
            y: e.target.y(),
          }
        : text
    );
    setTexts(newText);
  };

  const handleStageClick = (e) => {
    const clickedOnImage = e.target.className === "Image";
    const clickedOnText = e.target.className === "Text";
    if (!clickedOnText) {
      selectShape(null);
    }
    if (!clickedOnImage) {
      selectShape(null);
    }
  };

  const handleImageClick = (src) => {
    const id = new Date().getTime();
    const newImage = {
      id,
      x: stageRef.current.width() / 2,
      y: stageRef.current.height() / 2,
      src,
    };

    setImages((prevImages) => [...prevImages, newImage]);

    selectShape(id);
  };

  const handleTextClick = (src) => {
    const id = new Date().getTime();
    const newText = {
      id,
      x: stageRef.current.width() / 2,
      y: stageRef.current.height() / 2,
      src,
    };

  setTexts((prevTexts) => [...prevTexts, newText]);

  selectShape(id);
  };

  return (
    <div id="drag-items">
      <NavBar
        stageRef={stageRef} 
      />
      <div id="constructor-container">
        <ConstructorMenu
         setImagesVisible={setImagesVisible}
         setMoldsVisible={setMoldsVisible}
         setGeometryVisiable={setGeometryVisiable}
         setFontsVisible={setFontsVisible}
         setFavoritesVisible={setFavoritesVisible}
        />
        {imagesVisible && (
        <div id="files">
          <Image
            handleImageClick={handleImageClick}
            handleDragStart={handleDragStart}
            setImagesVisible={setImagesVisible}
          />
        </div>
        )}
        {moldsVisible && (
        <div id="files">
          <Molds
            handleImageClick={handleImageClick}
            handleDragStart={handleDragStart}
            setMoldsVisible={setMoldsVisible}
          />
        </div>
        )}
        {geometryVisible && (
          <div id="files">
            <Geometry
              handleImageClick={handleImageClick}
              handleDragStart={handleDragStart}
              setGeometryVisiable={setGeometryVisiable}
            />
          </div>
        )}
        {fontsVisible && (
          <div id="files">
            <Fonts
              handleDragStart={handleDragStart}
              setFontsVisible={setFontsVisible}
              handleTextClick={handleTextClick}
            />
          </div>
        )}
        {favoritesVisible && (
          <div id="files">
            <Favourite
              setFavoritesVisible={setFavoritesVisible}
            />
          </div>
        )}
        <div id="constructor">
          <Button
            selectedId={selectedId}
            images={images}
            setImages={setImages}
            selectShape={selectShape}
          />
          <div onDrop={handleDrop} onDragOver={(e) => e.preventDefault()}>
            <Stage
              width={810}
              height={456}
              className="stage-container"
              ref={stageRef}
              onClick={handleStageClick}
            >
              <Layer>
                {images.map((image) => (
                  <URLImage
                    key={image.id}
                    image={image}
                    isSelected={image.id === selectedId}
                    onSelect={() => selectShape(image.id)}
                    onChange={handleImageDragMove}
                  />
                ))}
                {texts.map((texts) => (
                  <URLText
                    x={50}
                    y={50}
                    text={text}
                    onTextChange={(value) => setText(value)}
                    width={width}
                    height={height}
                    selected={selected}
                    onTextResize={(newWidth, newHeight) => {
                      setWidth(newWidth);
                      setHeight(newHeight);
                    }}
                    onClick={() => {
                      setSelected(!selected);
                    }}
                    onTextClick={(newSelected) => {
                      setSelected(newSelected);
                    }}
                    onChange={handleTextDragMove}
                  />
                ))}
              </Layer>
            </Stage>
          </div>
        </div>
      </div>
    </div>
  );
};

export default App;
