import React, { useRef } from "react";
import { Image, Transformer } from "react-konva";
import useImage from "use-image";

// Функциональный компонент URLImage, представляющий изображение на холсте
const URLImage = ({ image, isSelected, onSelect, onChange, ...props }) => {
  const [img] = useImage(image.src);
  const imageRef = useRef();
  const trRef = useRef();

  React.useEffect(() => {
    if (isSelected && trRef.current) {
      trRef.current.nodes([imageRef.current]);
      trRef.current.getLayer().batchDraw();
    }
  }, [isSelected]);

  return (
    <>
      {/* Компонент Image используется для отображения изображения на холсте */}
      <Image
        image={img} // image - объект с изображением, загруженным с помощью useImage
        x={image.x}
        y={image.y}
        offsetX={img ? img.width / 2 : 0} // Если изображение загружено, то смещение по оси X равно половине ширины изображения, иначе 0
        offsetY={img ? img.height / 2 : 0}
        ref={(node) => {
          imageRef.current = node;
        }}
        {...props}
        draggable
        onClick={onSelect}
        onTap={onSelect}
        onDragStart={() => {
          onSelect();
          if (trRef.current) {
            trRef.current.nodes([imageRef.current]);
            trRef.current.getLayer().batchDraw();
          }
        }}
        onDragMove={(e) => {
          onChange(e, image.id);
        }}
      />
      {isSelected && (
        <Transformer
          // node={textNode}
          ref={(node) => {
            trRef.current = node;
          }}
          boundBoxFunc={(oldBox, newBox) => {
            // limit transformation
            if (newBox.width < 5 || newBox.height < 5) {
              return oldBox;
            }
            return newBox;
          }}
          rotateEnabled={true}
          anchorCornerRadius={5}
          borderStroke="#7F6BFF"
          borderStrokeWidth={2.5}
          anchorFill="#fff"
          anchorStroke="#7F6BFF"
          anchorStrokeWidth={2.5}
        />
      )}
    </>
  );
};

export default URLImage;
