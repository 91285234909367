// import a from "./img/лого.png";
// // import b from "./img/звезда.png";
// import c from "./img/челик.png";
// import d from "./img/прямоугольник-2.png";
// import e from "./img/Молдер.png";
// import f from "./img/куар.png";
// import g from "./img/творить.png";
// import h from "./img/flower.svg";
import aa from "./img/mold1.png";
import circle from "./img/circle.svg"
import star from "./img/star.svg"
import triangle from "./img/triangle.svg"
import square from "./img/square.svg"
import sticker1 from "./img/1.svg"
import sticker2 from "./img/2.svg"
import sticker3 from "./img/3.svg"
import sticker4 from "./img/4.svg"
import VK from "./img/VK-me.svg"
import VK1 from "./img/VK-me1.svg"
import VK2 from "./img/VK-me2.svg"
import VK3 from "./img/VK-me3.svg"
import VK4 from "./img/VK-me4.svg"
import VK5 from "./img/VK-me5.svg"

export const imagesData = [
  // // {
  // //   id: 1,
  // //   src: b,
  // // },
  // {
  //   id: 2,
  //   src: c,
  // },
  // {
  //   id: 3,
  //   src: a,
  // },
  // // {
  // //   id: 4,
  // //   src: d,
  // // },
  // {
  //   id: 5,
  //   src: e,
  // },
  // {
  //   id: 6,
  //   src: f,
  // },
  // {
  //   id: 7,
  //   src: g,
  // },
  // {
  //   id: 8,
  //   src: h,
  // },
];

export const moldsData = [
  {
    id: 1,
    src: aa,
    width: 810,
    height: 456,
  }
];

export const geomData = [
  {
    id: 1,
    src: circle,
  },
  {
    id: 2,
    src: star,
  },
  {
    id: 3,
    src: triangle,
  },
  {
    id: 4,
    src: square,
  },
];

export const stickerData = [
  {
    id: 1,
    name: "Стикерпак “Фантомий”",
    src: VK,
    stickers: [
      { src: VK1 },
      { src: VK2 },
      { src: VK3 },
      { src: VK4 },
      { src: VK5 },
    ]
  },
  {
    id: 2,
    name: "Стикерпак 2",
    src: sticker1,
    stickers: [
      { src: sticker3 },
      { src: sticker4 },
    ]
  },
  {
    id: 3,
    name: "Стикерпак 3",
    src: sticker2,
    stickers: [
      { src: sticker3 },
      { src: sticker4 },
    ]
  },
  {
    id: 4,
    name: "Стикерпак 3",
    src: sticker3,
    stickers: [
      { src: sticker3 },
      { src: sticker4 },
    ]
  },
  {
    id: 5,
    name: "Стикерпак 3",
    src: sticker4,
    stickers: [
      { src: sticker3 },
      { src: sticker4 },
    ]
  },

];

export const fontsData = [
  {
    id: 1,
    family: '"Grey Qo", cursive',
    preview: 'Felidae',
    weight: 200,
    style: 'normal',
    subText: 'Helvetica Neue'
  },
  {
    id: 2,
    family: '"Poppins", sans-serif',
    preview: 'Impact',
    weight: 800,
    style: 'normal',
    subText: 'Clash Display'
  },
  {
    id: 3,
    family: '"Dancing Script", cursive',
    preview: 'Argue',
    weight: 100,
    style: 'normal',
    subText: 'SF Pro Display'
  },
  {
    id: 4,
    family: '"Kalnia Glaze", serif',
    preview: 'BEBAS',
    weight: 100,
    style: 'normal',
    subText: 'Monsieur La Doulaise'
  }
];
